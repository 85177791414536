.main {
  width: 100%;

  .firstView {
    display: grid;
    grid-template: 1fr / minmax(min-content, 1fr) 1fr;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 1rem;

    .aboutMe {
      display: grid;
      align-content: center;
      justify-content: center;

      h4,
      h2 {
        margin: 0;
      }

      h4 {
        font-size: 2rem;
        font-weight: 500;
      }

      h2 {
        font-size: 3rem;
      }

      @media screen and (max-width: 680px) {

        h4,
        h2 {
          text-align: center;
        }

        h4 {
          font-size: 1.5rem;
        }

        h2 {
          font-size: 2rem;
        }
      }
      
      @media screen and (max-width: 1024px) {
        h4 {
          font-size: 1.75rem;
        }

        h2 {
          font-size: 2rem;
        }
      }
    }



    @media screen and (max-width: 680px) {
      grid-template: 50% / 1fr;
    }

    .imageContainer {
      display: grid;
      align-items: center;
      justify-items: center;
      grid-template: 1fr / 1fr;

      @media screen and (max-width: 680px) {
        order: -1;
      }
    }

    .image {
      width: 75%;
      height: 0;
      padding-top: 75%;
      // aspect-ratio: auto;
      background: url('../public/assets/images/profile.jpg') grey center / cover no-repeat;
      object-fit: cover;
      border-radius: 0.5rem;
      box-shadow: 1px 1px 2px 1px rgba($color: #000000, $alpha: 0.2);
      transition: all ease-in-out 240ms;

      &:hover {
        box-shadow: 1px 2px 4px 3px rgba($color: #000000, $alpha: 0.2);
      }

      @media screen and (max-width: 680px) {
        justify-self: center;

        h4 {
          font-size: 1.5rem;
        }

        h2 {
          font-size: 2%;
        }
      }
    }
  }
}