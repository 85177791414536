.header {
    padding: 2.5rem;
    position: sticky;
    background-color: rgba($color: #ffffff, $alpha: 0.75);
    backdrop-filter: blur(1.25rem);
    top: 0;
    width: 100vw;
    box-sizing: border-box;

    .aligner {
        display: grid;
        grid-template: max-content / 1fr max-content;
        column-gap: 2rem;

        @media screen and (max-width: 680px) {
            grid-template: max-content / 1fr;

            .nav {

                a {
                    padding-right: 0;
                }
            }
        }

        .nav {
            display: grid;
            grid-auto-flow: column dense;
            column-gap: 1.5rem;
            padding-bottom: 0.5rem;
            align-items: flex-end;

            a {
                position: relative;
                padding-right: 1rem;
                font-weight: 500;

                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-bottom: 1px solid black;
                }

                &,
                &:hover,
                &:visited {
                    color: black;
                    text-decoration: none;
                }
            }
        }
    }

    #title {

        &,
        &:hover,
        &:visited {
            color: black;
            text-decoration: none;
        }

        h1 {
            font-weight: normal;
            margin: 0;
        }
    }

    main {
        .firstView {
            width: 100%;
            height: 100%;
            background-color: grey;
        }
    }
}